<template>
  <div class="content d-flex justify-content-center align-items-center">
    <form class="login-form" @submit.prevent="passwordRecovery">
      <div class="card mb-0">
        <div class="card-body">
          <div class="text-center mb-3">
            <img
              src="@/assets/img/logo.png"
              class="img-fluid mx-auto d-block"
              alt="Logo"
              width="60%"
            />
          </div>
          <div class="form-group form-group-feedback form-group-feedback-left">
            <input
              type="email"
              class="form-control"
              :class="{ 'is-invalid': $v.email.$error }"
              placeholder="E-mail"
              v-model="$v.email.$model"
            />
            <div class="form-control-feedback">
              <i class="icon-mail5 text-muted" />
            </div>
          </div>
          <div class="form-group">
            <button
              type="submit"
              class="btn btn-primary btn-block"
              title="Recuperar Senha"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Recuperar Senha</span>
            </button>
          </div>
          <div class="text-center" title="Login">
            <router-link to="/">Login</router-link>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'PasswordRecovery',

  data() {
    return {
      email: '',
      disableButton: false,
    }
  },

  methods: {
    async passwordRecovery() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch(
            'passwordRecovery',
            this.email,
          )

          this.resetForm()

          if (response && response.message) {
            this.$root.$children[0].$refs.notification.success(response.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        } catch (error) {
          this.resetForm()

          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },

    resetForm() {
      this.disableButton = false
      this.email = ''
      this.$v.$reset()
    },
  },

  validations: {
    email: {
      required,
      email,
    },
  },
}
</script>
